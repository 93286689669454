import React, { useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import CardContainer from "../Grid/card_container";
import Mostplayed from "../Grid/most-played";
import Adventure from "../Grid/adventure";
import InterAd1 from "../Ads/nativead";
import CoinsShow from "../../Assets/Projects/coins_show_animation.gif";
import Rewardgift from "../popups/rewardgift";

if (localStorage.length === 0) {
  localStorage.setItem("coins", 0);
  localStorage.setItem("ads_reward", 0);
}

if (!localStorage.getItem("coins")) {
  localStorage.setItem("coins", 0);
}

if (!localStorage.getItem("ads_reward")) {
  localStorage.setItem("ads_reward", 0);
}

function Home() {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle;
        adsbygoogle.push({});
      } catch (e) {
        console.error(e);
      }
    };

    let interval = setInterval(() => {
      if (window.adsbygoogle) {
        pushAd();
        clearInterval(interval);
      }
    }, 300);

    return () => {
      clearInterval(interval);
    };
  }, []);

  const [showElement, setShowElement] = useState(false);

  useEffect(() => {
    const visitedBefore = localStorage.getItem("visitedBefore");

    if (!visitedBefore) {
      setShowElement(true);
      localStorage.setItem("visitedBefore", true);
    }

    return () => {
      setShowElement(false);
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowModal(true);
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowElement(false); // Hide the element after 4 seconds
    }, 4000);

    return () => clearTimeout(timer);
  }, []); // Run this effect only once after component mount

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <section>
      <Container fluid className="" id="home">
        <Container className="home-content">
          <Rewardgift show={showModal} onHide={closeModal} />
          {showElement && (
            <img
              src={CoinsShow}
              alt="coinsshow"
              className="position-absolute img-fluid z-3"
            />
          )}

          <CardContainer />

          <div className="d-flex justify-content-center mb-2">
            <div style={{ width: "100%", height: "300px" }}>
              <ins
                className="adsbygoogle adslot_1"
                style={{ display: "block" }}
                data-ad-client="ca-pub-8026299207830445"
                data-ad-slot="4327847264"
              ></ins>
            </div>
          </div>
          <Mostplayed />
          <Adventure />
          <InterAd1 />
        </Container>
      </Container>
    </section>
  );
}

export default Home;
