import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";
// import Adventure from "../Grid/adventure";
import Notenoughcoin from "../popups/notenough-open";
import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";

function Secondscreen() {
  const { id } = useParams();
  const navigate = useNavigate();

  // this is for ad
  useEffect(() => {
    const pushAd = () => {
      try {
        const adsbygoogle = window.adsbygoogle
        console.log({ adsbygoogle })
        adsbygoogle.push({})
      } catch (e) {
        console.error(e)
      }
    }

    let interval = setInterval(() => {
      // Check if Adsense script is loaded every 300ms
      if (window.adsbygoogle) {
        pushAd()
        // clear the interval once the ad is pushed so that function isn't called indefinitely
        clearInterval(interval)
      }
    }, 300)

    return () => {
      clearInterval(interval)
    }
  }, [])
  

  // Start for not enought coin
  const [showModal, setShowModal] = useState(false);
  let coins = Number(localStorage.getItem("coins"));

  const openModalnocoin = (geturl) => {
    // if (coins && coins < 50) {
      window.adBreak({
        type: "start",
        name: "game_started",
        adBreakDone: (placementInfo) => {
          console.log(placementInfo.breakStatus);

          // let oldcoins = localStorage.getItem('coins')
          // let deductcoins= Number(oldcoins)-50
          // localStorage.setItem('coins', deductcoins)

          navigate("/gamedetails/" + geturl.data);
        },
      })
  };

  const ToPlayerzone = (url)=>{
    if(coins < 50){
      setShowModal(true);
    }else{

      window.adBreak({
        type: "start",
        name: "game_started",
        adBreakDone: (placementInfo) => {
          console.log(placementInfo.breakStatus);

          let oldcoins = localStorage.getItem('coins')
          let deductcoins= Number(oldcoins)-50
          localStorage.setItem('coins', deductcoins)
          navigate("/playerzone/"+ url.data)
        },
      })

    }
  }

  const closeModal = () => {
    setShowModal(false);
  };

  let mygamelist = [
    {
      id: 1,
      name: "Slider",
      thumb: "https://quizzigo.com/games/slider/thumb_2.png",
      url: "https://quizzigo.com/games/slider/",
      desc: "Slider",
    },
    {
      id: 2,
      name: "Puzzle Color",
      thumb: "https://quizzigo.com/games/puzzle-color/thumb_2.png",
      url: "https://quizzigo.com/games/puzzle-color/",
    },
    {
      id: 3,
      name: "Fill Dot",
      thumb: "https://quizzigo.com/games/fill-dotted-/thumb_2.png",
      url: "https://quizzigo.com/games/fill-dotted-",
    },
    {
      id: 4,
      name: "Candy Blocks",
      thumb: "https://quizzigo.com/games/candy-blocks/thumb_2.jpg",
      url: "https://quizzigo.com/games/candy-blocks/",
    },
    {
      id: 5,
      name: "Draw Master",
      thumb: "https://quizzigo.com/games/drawmaster/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/drawmaster/index.html",
    },
    {
      id: 6,
      name: "Pattern String",
      thumb: "https://quizzigo.com/games/pattern-strings/thumb_2.jpg",
      url: "https://quizzigo.com/games/pattern-strings",
    },
    {
      id: 7,
      name: "Paper Fold",
      thumb: "https://quizzigo.com/games/paper-fold/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/paper-fold/index.html",
    },
    {
      id: 8,
      name: "House Paint",
      thumb: "https://quizzigo.com/games/house-paint/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/house-paint/index.html",
    },
    {
      id: 9,
      name: "Basket Ball",
      thumb: "https://quizzigo.com/games/basketball-challenge/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/basketball-challenge/index.html",
    },
    {
      id: 10,
      name: "Draw Master",
      thumb: "https://quizzigo.com/games/drawmaster/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/drawmaster/index.html",
    },
    {
      id: 11,
      name: "Pixel Slide",
      thumb: "https://quizzigo.com/games/pixel-slilde/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/pixel-slilde/index.html",
    },
    {
      id: 12,
      name: "Troll Thief",
      thumb: "https://quizzigo.com/games/troll-thief/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/troll-thief/index.html",
    },
    {
      id: 13,
      name: "Toilet Rush",
      thumb: "https://quizzigo.com/games/toilet-race/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/toilet-race/index.html",
    },
    {
      id: 14,
      name: "Roll Ball",
      thumb: "https://quizzigo.com/games/roll-the-ball/thumb_2.jpg",
      url: "https://quizzigo.com/games/roll-the-ball",
    },
    {
      id: 15,
      name: "Onet 3D",
      thumb: "https://quizzigo.com/games/onet-3d/thumb_2.jpg",
      url: "https://quizzigo.com/games/onet-3d",
    },
    {
      id: 16,
      name: "Grab Pack",
      thumb: "https://quizzigo.com/games/grab-pack/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/grab-pack/index.html",
    },
    {
      id: 17,
      name: "Poly Art",
      thumb: "https://quizzigo.com/games/poly-art/thumb_2.jpg",
      url: "https://quizzigo.com/games/poly-art",
    },
    {
      id: 18,
      name: "Free Bird",
      thumb: "https://milodygames.com/games/free-bird/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/free-bird/index.html",
    },
    {
      id: 19,
      name: "Monster Go",
      thumb: "https://quizzigo.com/games/monster-go/thumb_2.jpg",
      url: "https://quizzigo.com/games/monster-go",
    },
    {
      id: 20,
      name: "Fuit Master",
      thumb: "https://quizzigo.com/games/fruit-master/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/FruitMaster/index.html",
    },
    {
      id: 21,
      name: "Flip Bottle",
      thumb: "https://quizzigo.com/games/flip-bottle/thumb_2.jpg",
      url: "https://gamezy.milodygames.com/games/bottle-flip/index.html",
    },
    {
      id: 22,
      name: "Fall Beans",
      thumb: "https://quizzigo.com/games/fall-beans/thumb_2.png",
      url: "https://quizzigo.com/games/fall-beans",
    },
    {
      id: 23,
      name: "2k Shoot",
      thumb: "https://quizzigo.com/games/2k-shoot/thumb_2.png",
      url: "https://quizzigo.com/games/2k-shoot",
    },
    {
      id: 24,
      name: "Match Shapes",
      thumb: "https://quizzigo.com/games/match-shapes/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/match-shapes/index.html",
    },
    {
      id: 25,
      name: "Save The Dog",
      thumb: "https://quizzigo.com/games/save-the-dog/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/save-the-dog/index.html",
    },
    {
      id: 26,
      name: "Theeth Cleaner",
      thumb: "https://quizzigo.com/games/teeth-runner/thumb_2.png",
      url: "https://gamezy.milodygames.com/games/teeth-runner/index.html",
    },
    {
      id: 27,
      name: "Cricket Champion",
      thumb: "https://quizzigo.com/games/Cricket/512.png",
      url: "https://quizzigo.com/games/Cricket/",
    },
    {
      id: 28,
      name: "Jewel Classic",
      thumb: "https://quizzigo.com/games/jewel-classic/thumb_2.jpg",
      url: "https://quizzigo.com/games/jewel-classic",
    },
    {
      id: 29,
      name: "Match Pair",
      thumb: "https://quizzigo.com/games/match-pair/thumb_2.jpg",
      url: "https://quizzigo.com/games/match-pair",
    },
  ];
  const gamedata = mygamelist.find((game) => game.id === Number(id));
  //   const gameurl = window.location.href;
  //   const iframeRef = useRef(null);

  return (
    <div className="container mt-5 pt-5">
      <Notenoughcoin show={showModal} onHide={closeModal} />
      <div className="row row-cols-sm-1 row-cols-md-2 row-cols-1">
        <div className="col d-flex bg-dark text-white p-2">
          
          <div className="my-auto">
            <img src={gamedata.thumb} alt="gameimg" className="img-fluid" width={300} />
          </div>

          <div className="ms-2 w-100 text-center my-auto">
            <h5>
              <strong> {gamedata.name}</strong>
            </h5>
            <span className="">-50 coins 🟡</span>

            <div className="mt-4">
              <p>Play and Make More Coins</p>
              <Button className="w-100" onClick={() => ToPlayerzone({data: id})}>Play Now</Button>
            </div>
          </div>

        </div>
        <div className="d-flex justify-content-center mb-2">
              <div style={{width:"100%", height:"300px"}}>
                {/* Here's Place your Native ad */}
                    <ins className="adsbygoogle adslot_1"
                    style={{ display: "block"}}
                    data-ad-client="ca-pub-8026299207830445"
                    data-ad-slot="4327847264">
                </ins>
              </div>
            </div>
      </div>
  <div className="row row-cols-sm-6 d-flex justify-content-center text-center">     
        {mygamelist.map((mygamelist) => (
          <div className="col m-2" style={{ height: "140px", width: "140px" }}>
            <div className="game-card-1 ">
              <div
                onClick={() => openModalnocoin({ data: mygamelist.id })}
                style={{ cursor: "pointer" }}
              >
                <img src={mygamelist.thumb} alt="gmaeimg" />
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="d-flex justify-content-center mb-2">
              <div style={{width:"728px", height:"90px"}}>
                {/* Here's Place your Native ad */}
                    <ins className="adsbygoogle"
                    style={{ display: "block"}}
                    data-ad-client="ca-pub-8026299207830445"
                    data-ad-slot="4327847264"
                   >
                </ins>
              </div>
            </div>
    </div>
  );
}

export default Secondscreen;

