// MyModal.js
import React, {useState} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
// import SpinWheel from '../Reward/rewardspin'
// import giftimg from '../Assets/Projects/ads-reward.gif'
import imgsrcreward from "../../Assets/Projects/ads-reward.gif"
import { useToasts } from 'react-toast-notifications';
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

function Rewardgift({ show, onHide }) {
  const { addToast } = useToasts();
  const [setShowModal] = useState(false);
  const navigate = useNavigate();
  const { id } = useParams();


  const dailyreward = () => {
    let oldReward = Number(localStorage.getItem('ads_reward'))

    if (oldReward < 5) {
      let adRewardFlag = true;
      let adWatch = false;
      
      window.adBreak({
        type: "reward",
        name: "dailyreward",
        beforeReward: (showAdFn) => {
          adRewardFlag = false;
          showAdFn();
        },
        adDismissed: () => {console.log("Ad Dismissed before time"); addToast('You exceeded the ad limit ', { appearance: 'warning', autoDismiss: true });},
        adViewed: () => {
          console.log("Ad Dismissed after time");
          adRewardFlag = true;
          adWatch = true;

          console.log("after adviewed close popup check");
          onHide()

        },
        adBreakDone: () => {
          if (adRewardFlag && adWatch) {
            let oldcoins = localStorage.getItem('coins')
            localStorage.setItem("coins", Number(oldcoins)+100);

            let oldReward = localStorage.getItem('ads_reward')
            localStorage.setItem("ads_reward", Number(oldReward)+1);
            addToast('100 Coins Rewarded!', { appearance: 'success', autoDismiss: true });
            navigate("/playerzone/"+ id)

            console.log('after breakdone for close popup');

            onHide()
            
          } else {
            addToast('RewardAds Not Available', { appearance: 'error', autoDismiss: true });
            onHide()
            console.log("RewardAds not available, Try Again");

          }
        },
      });
    } else {
      addToast('Daily Reward limit reached', { appearance: 'error', autoDismiss: true });
    }
  };


  return (
    <Modal
    show={show} // Use show prop to control visibility
    onHide={onHide} // Call onHide function to close modal
    // {...props}
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    style={{backdropFilter:"blur(30px)"}}
  >
    <Modal.Header closeButton>
      <Modal.Title id="contained-modal-title-vcenter" >
          Reward
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <div className='row row-cols-1'>
          <div className='col'><img src={imgsrcreward} className="reward-img" alt="avatar"/></div>
          <div className='col'><h3>100 Coins available for you </h3>
              <p>Whatch ad and get Reward coins</p>
          </div>
          <div className='col'><Button onClick={dailyreward}>Claim</Button></div>
      </div>

    </Modal.Body>
    <Modal.Footer>
      {/* <Button onClick={props.onHide}>Close</Button> */}
    </Modal.Footer>
  </Modal>
  );
}

export default Rewardgift;
